











































































































































































































































































































































































































































































@import '../../../../assets/styles/components/modal-cadastro';

.v-toolbar {
  margin: 16px;
}

.v-select__selections {
  height: 74px;
}

.margin-right {
  margin-right: 16px;
}
